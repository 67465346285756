<template>
  <VDialog class="relative" width="800px" min-height="50vh" :load="load" @close="doClose">
    <VHeading level="4">
      {{ props.formId ? localize('title.edit') : localize('title.create') }}
    </VHeading>
    <div class="flex flex-col gap-3 w-full max-h-[70vh] p-1 pr-2 overflow-y-auto">
      <VInput
        v-model="formTitle"
        :label="getLabelHtml('title')"
        :placeholder="localize('form.title')"
        :readonly="getDisabledState('title')"
      />
      <VSpan>{{ localize('category.general') }}</VSpan>
      <VSelect
        v-model="formTemplateId"
        :options="formTemplateIdOptions"
        search
        :label="getLabelHtml('template_id')"
        :label-tooltip="localize('form.template_id_info')"
        :placeholder="localize('form.template_id_placeholder')"
        :readonly="getDisabledState('template_id')"
      />
      <VSelect
        v-model="formPartyId"
        :options="formPartyIdOptions"
        :label="getLabelHtml('party_id')"
        :placeholder="localize('form.party_id_placeholder')"
        :readonly="getDisabledState('party_id')"
      />
      <VSelect
        v-model="formUserId"
        :options="formUserIdOptions"
        search
        :label="getLabelHtml('user_id')"
        :placeholder="localize('form.user_id_placeholder')"
        :readonly="getDisabledState('user_id')"
      />
      <VSpan>{{ localize('category.submissions') }}</VSpan>
      <VSelect
        v-if="formResponseModeValues.length > 1"
        v-model="formResponseMode"
        :options="formResponseModeOptions"
        :label="getLabelHtml('response_mode')"
        :placeholder="localize('form.response_mode')"
        :readonly="getDisabledState('response_mode')"
      />
      <div v-if="formResponseMode === 'generate_via_url' && formUrl" class="flex mx-2 items-center">
        {{ localize('form.url') }}:
        <div class="italic ml-2" v-text="formUrl" />
        <VButton variant="text" icon :title="localize('copy_url')" @click="copyField('url')">
          <VIcon name="Solid/clipboard" />
        </VButton>
      </div>
      <div v-else-if="formResponseMode === 'generate_via_request' && formId" class="flex mx-2 items-center">
        {{ localize('form.id') }}:
        <div class="italic ml-2" v-text="formId" />
        <VButton variant="text" icon :title="localize('copy_id')" @click="copyField('id')">
          <VIcon name="Solid/clipboard" />
        </VButton>
      </div>
      <VCheckbox
        v-if="formResponseMode === 'generate_via_url'"
        v-model="formSubmitAnonymous"
        :label="localize('form.submit_anonymous')"
        :readonly="getDisabledState('submit_anonymous')"
      />
      <template v-if="!formSubmitAnonymous">
        <VMultiSelect
          v-model="formSubmitEmailWhitelist"
          badges
          open
          :label="getLabelHtml('submit_email_whitelist')"
          :placeholder="localize('form.submit_email_whitelist_placeholder')"
          :readonly="getDisabledState('submit_email_whitelist')"
          :options="formSubmitEmailWhitelistOptions"
        />
        <VCheckbox
          v-model="formSubmitEmailVerification"
          :label="localize('form.submit_email_verification')"
          :readonly="getDisabledState('submit_email_verification')"
        />
      </template>
      <VNumber
        v-model="formExpiresInMinutes"
        :label="getLabelHtml('expires_in_minutes')"
        :placeholder="localize('form.expires_in_minutes')"
        :readonly="getDisabledState('expires_in_minutes')"
      />
      <template v-if="formResponseMode === 'generate_via_url'">
        <VSelect
          v-model="formSubmitLimit"
          :options="formSubmitLimitOptions"
          :label="getLabelHtml('submit_limit')"
          :placeholder="localize('form.submit_limit')"
          :readonly="getDisabledState('submit_limit')"
        />
        <VCheckbox
          v-model="formSubmitLimitMessageToggle"
          :label="localize('form.submit_limit_message_toggle')"
          :readonly="getDisabledState('submit_limit_message')"
        />
        <VTextArea
          v-if="formSubmitLimitMessageToggle"
          v-model="formSubmitLimitMessage"
          :label="getLabelHtml('submit_limit_message')"
          :placeholder="localize('form.submit_limit_message')"
          :readonly="getDisabledState('submit_limit_message')"
        />
      </template>
      <VSpan>{{ localize('category.appearance') }}</VSpan>
      <VCheckbox
        v-model="formDisplayTitle"
        :label="localize('form.display_title')"
        :readonly="getDisabledState('display_title')"
      />
      <VCheckbox
        v-model="formDisplayLogo"
        :label="localize('form.display_logo')"
        :readonly="getDisabledState('display_logo')"
      />
      <VMultiSelect
        v-if="formResponseMode !== 'generate_via_url'"
        v-model="formCSPFrameAncestors"
        badges
        open
        :label="getLabelHtml('csp_frame_ancestors')"
        :placeholder="localize('form.csp_frame_ancestors_placeholder')"
        :readonly="getDisabledState('csp_frame_ancestors')"
        :options="formCSPFrameAncestorsOptions"
      />
      <VSpan>{{ localize('category.after') }}</VSpan>
      <VCheckbox
        v-model="formSubmitMessageToggle"
        :label="localize('form.submit_message_toggle')"
        :readonly="getDisabledState('submit_message')"
      />
      <VTextArea
        v-if="formSubmitMessageToggle"
        v-model="formSubmitMessage"
        :label="getLabelHtml('submit_message')"
        :placeholder="localize('form.submit_message')"
        :readonly="getDisabledState('submit_message')"
      />
      <VCheckbox
        v-model="formAcceptChanges"
        :label="localize('form.accept_changes')"
        :readonly="getDisabledState('accept_changes')"
      />
      <VCheckbox
        v-model="formSubmitExport"
        :label="localize('form.submit_export')"
        :readonly="getDisabledState('submit_export')"
      />
      <template v-if="!formSubmitAnonymous">
        <VCheckbox
          v-model="formSubmitSendDocumentToggle"
          :label="localize('form.submit_send_document_toggle')"
          :readonly="getDisabledState('submit_send_document')"
        />
        <VSelect
          v-if="formSubmitSendDocumentToggle"
          v-model="formSubmitSendDocument"
          :options="formSubmitSendDocumentOptions"
          :label="getLabelHtml('submit_send_document')"
          :placeholder="localize('form.submit_send_document')"
          :readonly="getDisabledState('submit_send_document')"
        />
      </template>
      <VInput
        v-model="formSubmitRedirectUrl"
        :label="getLabelHtml('submit_redirect_url')"
        :placeholder="localize('form.submit_redirect_url_placeholder')"
        :readonly="getDisabledState('submit_redirect_url')"
      />
    </div>
    <div class="flex flex-row gap-4 mt-4 justify-end w-full">
      <VButton variant="outlined" color="neutral" @click="doClose">
        {{ localize('cancel') }}
      </VButton>
      <VButton v-if="props.edit" :readonly="!isValid" @click="doSave">
        {{ localize('save') }}
      </VButton>
    </div>
  </VDialog>
</template>

<script lang="ts" setup>
import { ref, computed, type UnwrapRef, watch } from 'vue'
import FormsApi from '@api/FormsApi'
import { useOrganisationStore } from '@stores/generic/organisation.store'
import { getActivePinia } from 'pinia'
import Utils from '../utils'
import type { V } from '@component-utils/types'
import VSelect from '@component-library/inputs/VSelect.vue'
import VSpan from '@component-library/labels/VSpan.vue'
import VInput from '@component-library/inputs/VInput.vue'
import VHeading from '@component-library/labels/VHeading.vue'
import VDialog from '@component-library/dialogs/VDialog.vue'
import VButton from '@component-library/buttons/VButton.vue'
import VIcon from '@component-library/labels/VIcon.vue'
import VCheckbox from '@component-library/inputs/VCheckbox.vue'
import VNumber from '@component-library/inputs/VNumber.vue'
import VTextArea from '@component-library/inputs/VTextArea.vue'
import VMultiSelect from '@component-library/inputs/VMultiSelect.vue'
import { useErrorToast, useToast } from '@component-utils/toasts'
import { useLocalize } from '@component-utils/localization'
import { useLoader } from '@component-utils/loader'

type BasicForm = Pick<
  Backend.Models.Form,
  | 'id'
  | 'accept_changes'
  | 'title'
  | 'csp_frame_ancestors'
  | 'party_id'
  | 'user_id'
  | 'display_logo'
  | 'display_title'
  | 'expires_in_minutes'
  | 'submit_redirect_url'
  | 'template_id'
  | 'response_mode'
  | 'submit_message'
  | 'submit_export'
  | 'submit_limit'
  | 'submit_limit_message'
  | 'submit_send_document'
  | 'submit_anonymous'
  | 'submit_email_whitelist'
  | 'submit_email_verification'
> & {
  url?: string
}

const store = useOrganisationStore(getActivePinia())

const formResponseModeDefault = computed(
  () => formResponseModeValues.value[formResponseModeValues.value.length - 1]
)
const formResponseModeValues = computed(() => {
  if (store.flippers.forms_generate_via_link) {
    return ['generate_via_request', 'generate_via_url'] as const
  } else {
    return ['generate_via_request'] as const
  }
})

const props = withDefaults(
  defineProps<{
    edit?: boolean
    formId?: string
  }>(),
  {
    edit: false
  }
)

// Store and fetch form after opening
const form = ref<BasicForm>()

const data = ref<{
  templates: {
    id: number
    name: string
    user_id: number
    // Optional, hydrated later
    parties?: string[]
    users?: {
      id: number
      email: string
    }[]
  }[]
}>({
  templates: []
})

const load = async () => {
  if (props.formId) {
    const { form: _form, hydrate } = await FormsApi.get<{
      form: BasicForm
      hydrate: UnwrapRef<typeof data>
    }>({ id: props.formId })

    form.value = _form
    data.value = hydrate
  } else {
    form.value = {
      id: 0,
      title: '',
      accept_changes: true,
      csp_frame_ancestors: [],
      party_id: '',
      submit_anonymous: false,
      submit_email_whitelist: [],
      submit_email_verification: true,
      user_id: 0,
      display_logo: true,
      display_title: true,
      response_mode: formResponseModeDefault.value,
      submit_message: null,
      submit_limit: 50,
      submit_limit_message: null,
      submit_send_document: null,
      template_id: null,
      expires_in_minutes: 10080,
      submit_redirect_url: '',
      submit_export: true
    }

    data.value = await FormsApi.hydrate()
  }
}

const getDisabledState = (field: keyof BasicForm): boolean | undefined => {
  if (!props.edit || !form.value) return true
  switch (field) {
    case 'title':
    case 'display_logo':
    case 'display_title':
    case 'submit_limit':
    case 'submit_limit_message':
    case 'submit_message':
    case 'accept_changes':
    case 'submit_export':
    case 'csp_frame_ancestors':
    case 'submit_redirect_url':
    case 'submit_email_whitelist':
    case 'submit_email_verification':
    case 'submit_send_document':
    case 'submit_anonymous':
    case 'user_id':
      return undefined
    case 'template_id':
    case 'party_id':
    case 'expires_in_minutes':
    case 'response_mode':
      return props.formId ? true : undefined
    default:
      return true
  }
}

const getLabelHtml = (field: keyof BasicForm): string => {
  return `${localize(`form.${field}`)}${isRequired(field) ? ` *` : ''}`
}

const requiredFields = computed<(keyof BasicForm)[]>(() => {
  if (formResponseMode.value === 'generate_via_url') {
    return [
      'title',
      'template_id',
      'party_id',
      'user_id',
      'submit_redirect_url',
      'expires_in_minutes',
      'response_mode',
      'submit_limit'
    ]
  } else {
    return [
      'title',
      'template_id',
      'party_id',
      'user_id',
      'submit_redirect_url',
      'expires_in_minutes',
      'response_mode'
    ]
  }
})

const isRequired = (field: keyof BasicForm): boolean => {
  return requiredFields.value.includes(field)
}

const isValid = computed(() => {
  const allFieldsPresent = requiredFields.value.every((f) => form.value?.[f])

  return allFieldsPresent && Utils.isURLValid(form.value?.submit_redirect_url)
})

const useProperty = <T extends keyof BasicForm, U = BasicForm[T]>(
  field: T,
  def: BasicForm[T],
  mapper?: { get: (value: BasicForm[T]) => U; set: (value: U) => BasicForm[T] }
) => {
  const internalComputed = computed({
    get: () => form.value?.[field] ?? def,
    set: (value) => {
      if (form.value) {
        form.value[field] = value
      }
    }
  })
  if (mapper) {
    return computed({
      get: () => mapper.get(internalComputed.value),
      set: (value) => (internalComputed.value = mapper.set(value))
    })
  } else {
    return internalComputed
  }
}

const useToggleProperty = <
  T extends keyof Pick<BasicForm, 'submit_limit_message' | 'submit_message' | 'submit_send_document'>
>(
  field: T,
  def: BasicForm[T]
) =>
  computed({
    get: () => form.value?.[field] !== null,
    set: (value) => {
      if (!form.value) return
      form.value[field] = value ? def : null
    }
  })

// Properties
const formTitle = useProperty('title', '')
const formDisplayTitle = useProperty('display_title', true)
const formDisplayLogo = useProperty('display_logo', true)
const formAcceptChanges = useProperty('accept_changes', true)
const formResponseMode = useProperty('response_mode', formResponseModeDefault.value)
const formSubmitMessage = useProperty('submit_message', null)
const formSubmitLimit = useProperty('submit_limit', 50)
const formSubmitLimitMessage = useProperty('submit_limit_message', null)
const formSubmitExport = useProperty('submit_export', true)
const formSubmitAnonymous = useProperty('submit_anonymous', false)
const formSubmitSendDocument = useProperty('submit_send_document', null)
const formSubmitEmailVerification = useProperty('submit_email_verification', true)
const formSubmitEmailWhitelist = useProperty('submit_email_whitelist', [])
const formPartyId = useProperty('party_id', '')
const formExpiresInMinutes = useProperty('expires_in_minutes', 10080)
const formSubmitRedirectUrl = useProperty('submit_redirect_url', '')
const formCSPFrameAncestors = useProperty('csp_frame_ancestors', [])
const formTemplateId = useProperty('template_id', null)
const formTemplate = computed(
  () =>
    data.value.templates.find((t) => t.id == formTemplateId.value) ??
    ({
      id: -1,
      name: '',
      user_id: 0
    } satisfies (typeof data.value.templates)[number])
)
const formUserId = useProperty('user_id', 0)
const formUrl = useProperty('url', undefined)
const formId = useProperty('id', 0)

watch(formTemplateId, async () => {
  // Hydrate users & parties
  if (!formTemplate.value.users || !formTemplate.value.parties) {
    const { users, parties } = await FormsApi.hydrate<{
      users: { id: number; email: string }[]
      parties: string[]
    }>({ query: { template_id: formTemplate.value.id } })

    formTemplate.value.users = users
    formTemplate.value.parties = parties
  }

  if (!formTemplate.value.parties.includes(formPartyId.value)) {
    formPartyId.value = ''
  }

  if (!formTemplate.value.users.some((u) => u.id == formUserId.value)) {
    formUserId.value = 0
  }
})

const formTemplateIdOptions = computed(() => data.value.templates.map((template) => ({
  value: template.id,
  label: `${template.name} (#${template.id})`
})))
const formPartyIdOptions = computed(() => (formTemplate.value.parties ?? []).map((party) => ({ value: party, label: party })))
const formUserIdOptions = computed(() => (formTemplate.value.users ?? []).map((user) => ({ value: user.id, label: user.email })))
const formResponseModeOptions = computed(() => formResponseModeValues.value.map<V.Select.Option<string>>((value) => ({
  value,
  label: localize(`form.response_modes.${value}`)
})))
const formSubmitLimitOptions = computed(() => [50, 100, 200, 300].map((value) => ({ value, label: String(value) })))
const formCSPFrameAncestorsOptions = computed(() => formCSPFrameAncestors.value.map((value) => ({ value, label: value })))
const formSubmitEmailWhitelistOptions = computed(() => formSubmitEmailWhitelist.value.map((value) => ({ value, label: value })))
const formSubmitSendDocumentOptions = computed(() => ['docx', 'pdf'].map((value) => ({ value, label: value })))

// Toggles, set value to empty string or null
const formSubmitLimitMessageToggle = useToggleProperty(
  'submit_limit_message',
  ''
)
const formSubmitMessageToggle = useToggleProperty('submit_message', '')
const formSubmitSendDocumentToggle = useToggleProperty('submit_send_document', 'pdf')

const emit = defineEmits<{
  close: [reload: boolean]
}>()

const localize = useLocalize('forms.form_dialog')

const doClose = () => emit('close', false)

const doSave = async () => {
  const loader = useLoader()
  try {
    loader.start({ message: 'Saving your changes ...', opaque: false })

    if (props.formId) {
      // Update existing
      await FormsApi.update({
        params: { id: props.formId },
        data: {
          form: form.value
        }
      })
    } else {
      // Save new one
      await FormsApi.create({
        data: {
          form: form.value
        }
      })
    }

    useToast({
      type: 'success',
      message: localize('save_success')
    }, {
      later: true
    })

    emit('close', true)
  } catch (e) {
    useErrorToast(e)
  } finally {
    loader.stop()
  }
}

const copyField = (field: keyof BasicForm) => {
  Utils.copyText(String(form.value?.[field]))

  useToast({
    message: localize(`copy_${field}_success`)
  })
}
</script>
