<template>
  <div
    class="modern-color-theme font-poppins"
    :class="{
      'w-full': props.wide,
      'w-min': !props.wide,
      'relative': props.disabled === 'loading'
    }"
    v-bind="containerProps"
    data-component-name="VButton"
  >
    <component
      :is="props.href ? 'a' : 'button'"
      v-bind="$attrs"
      :type="props.href ? 'undefined' : 'button'"
      :href="props.disabled ? undefined : props.href"
      :target="props.href && props.newTab ? '_blank' : undefined"
      class="
        block w-full flex items-center justify-around rounded-md gap-2
        overflow-ellipsis overflow-hidden focus-visible:outline whitespace-nowrap h-min
        focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500
      "
      :class="computedClass"
      :tabindex="props.tabindex"
      :disabled="!!props.disabled"
      :title="props.title"
      :aria-label="ariaLabel"
    >
      <slot name="prefix" />
      <slot />
      <slot name="suffix" />
    </component>
    <div v-if="props.disabled === 'loading'" class="absolute inset-0 flex items-center justify-center pointer-events-none">
      <VSpinner :size="props.size" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import type { Size, V } from '../../utils/types.js'
import VSpinner from '../labels/VSpinner.vue'
import { globalLocalize } from '@component-utils/localization'

defineOptions({
  name: 'VButton',
  inheritAttrs: false
})

const props = withDefaults(
  defineProps<{
    variant?: V.Button.Variant
    color?: V.Button.Color
    wide?: boolean
    disabled?: boolean | 'loading'
    size?: Extract<Size, 'xl' | 'lg' | 'base' | 'sm' | 'xs'>
    tabindex?: number | string
    title?: string
    icon?: boolean
    round?: boolean
    containerProps?: Record<string, unknown>
    href?: string
    raw?: boolean
    newTab?: boolean
    alt?: string
  }>(),
  {
    variant: 'contained',
    color: 'primary',
    size: 'base',
    wide: false,
    tabindex: undefined,
    title: undefined,
    icon: false,
    round: false,
    disabled: false,
    containerProps: () => Object.create(null),
    href: undefined,
    raw: false,
    alt: undefined,
    newTab: false
  }
)

const COLOR_SCHEMES: Record<`${V.Button.Variant}/${V.Button.Color}`, string> = {
  'contained/primary': 'border-primary-500 bg-primary-500 text-neutral-100 hover:bg-primary-450 hover:border-primary-450 active:bg-primary-550 active:border-primary-550',
  'outlined/primary': 'border-primary-500 bg-primary-100 text-primary-500 hover:text-primary-450 hover:border-primary-450 active:bg-primary-150 active:text-primary-550 active:border-primary-550',
  'text/primary': 'text-primary-500 hover:text-primary-450 active:text-primary-550',
  'contained/neutral': '', // TODO
  'outlined/neutral': 'border-neutral-300 hover:border-neutral-250 active:border-neutral-350 bg-neutral-100 active:bg-neutral-150 text-neutral-900 hover:text-neutral-850 active:text-neutral-950',
  'text/neutral': 'text-neutral-900 hover:text-neutral-850 active:text-neutral-950',
  'contained/red': 'border-red-500 bg-red-500 text-neutral-100 hover:bg-red-450 hover:border-red-450', // TODO
  'outlined/red': 'border-red-500 hover:border-red-450 active:border-red-550 bg-neutral-100 hover:bg-red-100 active:bg-red-150 text-red-500 hover:text-red-450 active:text-red-550 focus-visible:outline-red-500',
  'text/red': '' // TODO
}

const SIZE_SCHEMES: Record<`icon_${Extract<Size, 'xl' | 'lg' | 'base' | 'sm' | 'xs'>}` | `text_${Extract<Size, 'xl' | 'lg' | 'base' | 'sm' | 'xs'>}` | Extract<Size, 'xl' | 'lg' | 'base' | 'sm' | 'xs'>, string> = {
  'xl': 'py-2.5 px-3.5 text-base leading-6 font-semibold',
  'lg': 'py-2 px-3 text-sm leading-5 font-semibold',
  'base': 'py-1.5 px-2.5 text-sm leading-5 font-semibold',
  'sm': 'py-1 px-2 text-sm leading-5 font-semibold',
  'xs': 'py-1 px-2 text-xs leading-4 font-semibold',
  'text_xl': 'text-base leading-6 font-semibold',
  'text_lg': 'text-sm leading-5 font-semibold',
  'text_base': 'text-sm leading-5 font-semibold',
  'text_sm': 'text-sm leading-5 font-semibold',
  'text_xs': 'text-xs leading-4 font-semibold',
  'icon_xl': 'p-2.5',
  'icon_lg': 'p-2',
  'icon_base': 'p-1.5',
  'icon_sm': 'p-1',
  'icon_xs': 'p-1'
}

const computedClass = computed(() => {
  if (props.raw) return []

  const classes = []

  if (props.variant !== 'text') {
    classes.push('border')
  }

  if (props.round) {
    classes.push('!rounded-full')
  }

  classes.push(COLOR_SCHEMES[`${props.variant}/${props.color}`])
  classes.push(SIZE_SCHEMES[`${props.icon ? 'icon_' : props.variant === 'text' ? 'text_' : ''}${props.size}`])

  return classes
})

const ariaLabel = computed(() => {
  if (props.disabled === 'loading') {
    const prefix = props.alt ? `${props.alt} ` : ''
    return `${prefix}${globalLocalize('component-library.buttons.loading')}`
  }
  
  return props.alt
})

</script>

<style lang="scss" scoped>
  [data-component-name="VButtonGroup"] {
    & [data-component-name="VButton"] {
      > button, > a {
        border-radius: 0;
        border: none;
      }
    }

    & [data-component-name="VButton"]:first-child {
      > button, > a {
        border-radius: 0.375rem 0 0 0.375rem;
      }
    }

    & [data-component-name="VButton"]:last-child {
      > button, > a {
        border-radius: 0 0.375rem 0.375rem 0;
      }
    }
  }
</style>