<template>
  <VSSelectBase
    :id="props.id"
    :disabled="props.disabled"
    :readonly="props.readonly"
    :label="props.label"
    :label-tooltip="props.labelTooltip"
    :description="props.description"
    :open="props.open"
    :name="props.name"
    :search="props.search"
    :search-placeholder="props.searchPlaceholder"
    :allow-empty="props.allowEmpty"
    :search-options="props.searchOptions"
    multiple
    :options="options"
    :validation-result="validationResult"
    data-component-name="VMultiSelect"
    :is-selected="isSelected"
    :is-any-selected="isAnySelected"
    @select="select"
    @add="add"
    @set="set"
  >
    <slot name="display">
      <template v-if="selectedOptions.length > 0">
        <template v-if="props.badges">
          <div class="flex flex-wrap gap-1 -m-1 -mx-2">
            <template v-for="(selectedOption, selectedOptionIndex) of selectedOptions" :key="selectedOptionIndex">
              <div class="flex gap-2 items-center px-2 py-1 bg-primary-500 text-neutral-100 rounded">
                <VIcon v-if="selectedOption.icon" dense :name="selectedOption.icon" />
                <div class="truncate" :title="String(selectedOption.label ?? selectedOption.value)">{{ selectedOption.label ?? selectedOption.value }}</div>
                <VButton icon class="!border-none !p-0.5" :disabled="props.readonly || props.disabled" @click.prevent.stop="select(selectedOption.value)">
                  <VIcon dense color="white" name="Solid/close" />
                </VButton>
              </div>
            </template>
          </div>
        </template>
        <template v-else>
          <template v-for="(selectedOption, selectedOptionIndex) of selectedOptions" :key="selectedOptionIndex">
            <VIcon v-if="selectedOption.icon" dense class="mr-2" :name="selectedOption.icon" />
            <div class="truncate" :title="String(selectedOption.label ?? selectedOption.value)">{{ selectedOption.label ?? selectedOption.value }}</div>
            <div v-if="selectedOptionIndex < selectedOptions.length - 1" class="w-3">,&nbsp;</div>
          </template>
        </template>
      </template>
      <template v-else>
        <span class="text-neutral-500" :class="{ 'text-opacity-50': props.disabled }" :title="props.placeholder" v-text="props.placeholder || '&nbsp;'" />
      </template>
    </slot>
  </VSSelectBase>
</template>
<script lang="ts" generic="T" setup>
import { computed } from 'vue';
import { type V } from '../../utils/types';
import VIcon from './../labels/VIcon.vue'
import VSSelectBase from './components/VSSelectBase.vue'
import VButton from '../buttons/VButton.vue'
import { findSelectOption, type VSSelectBaseProps } from './helpers/select';
import { equal } from '@avvoka/shared';
import { createDefaultValidation, useValidation } from './helpers/validations';
import type { ValidationProps } from './helpers/types';

defineOptions({
  name: 'VMultiSelect'
})

const props = defineProps<VSSelectBaseProps<T> & ValidationProps<T[]> & {
  badges?: boolean
}>()

const modelValue = defineModel<T[]>({ required: true })

const { validationVisible, validationResult, isValid } = useValidation(
  modelValue,
  props,
  createDefaultValidation<T[]>(props, 'multiselect')
)

defineExpose({
  isValid
})

const isSelected = (option: V.Select.Item<T>) => modelValue.value.some((item) => equal(item, option.value))

const options = computed(() => {
  const values = modelValue.value

  if (values.length > 0) {
    return [
      ...values.filter((value) => !findSelectOption(value, 'value', props.options)).map((value) => ({
        value
      } as V.Select.Option<T>)),
      ...props.options
    ]
  } else {
    return props.options
  }
})

const selectedOptions = computed(() => modelValue.value.map((value) => findSelectOption(value, 'value', props.options)).filter((v) => v) as V.Select.Item<T>[])

const isAnySelected = computed(() => modelValue.value.length > 0)

const select = (value: T) => {
  const index = modelValue.value.findIndex((item) => equal(item, value))

  if (index === -1) {
    modelValue.value.push(value)
  } else {
    modelValue.value.splice(index, 1)
  }

  validationVisible.value = true
}

const add = (value: T) => {
  modelValue.value.push(value)
}

const set = (values: T[], action: 'set' | 'select' | 'unselect') => {
  if (action === 'set') {
    modelValue.value.splice(0, modelValue.value.length)
    for (const value of values) {
      modelValue.value.push(value)
    }
  } else if (action === 'select') {
    for (const value of values) {
      const index = modelValue.value.findIndex((item) => equal(item, value))
      if (index === -1) {
        modelValue.value.push(value)
      }
    }
  } else if (action === 'unselect') {
    for (const value of values) {
      const index = modelValue.value.findIndex((item) => equal(item, value))
      if (index !== -1) {
        modelValue.value.splice(index, 1)
      }
    }
  }

  validationVisible.value = true
}
</script>